/* stylelint-disable scss/load-no-partial-leading-underscore */
/* stylelint-disable-next-line scss/load-no-partial-leading-underscore */
@import url('tailwindcss/base');
@import url('tailwindcss/components');
@import url('tailwindcss/utilities');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-size: 14px ;
}

/* * {
  border: 1px solid red;
} */

* {
  font-family: 'Lato', sans-serif !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dd5a5a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bf3c3c;
}

@media (width <= 428px) {
  .nav-tabs .nav-item {
    display: inline-block;
    width: 50%;
    padding: 0.2rem 0.5rem;
    text-align: center;
    font-size: 0.5rem;
  }

  .nav-tabs .nav-link {
    display: block;
  }

}

.nav-tabs .nav-link {
  display: block;
}

.nav-tabs .nav-link:hover {
  background-color: #D1D5DB;
  border-radius: 30px !important;
}

.nav-tabs .nav-link:not(.active):hover {
  background-color: #D1D5DB;
}



.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link{
  display: flex;
  background-color: #fff;
  border: 1px solid #bf3c3c !important;
  border-radius: 30px !important;
  outline: none;
}

/* Eliminar el borde predeterminado de Bootstrap */
.nav-tabs .nav-link a {
    border: none !important;
}






button:active {
  transition: all .1s ease;
}
